<template>
  <div>
    <v-row justify="center" v-if="logo">
      <v-col cols="12" class="mb-0 pb-0">
        <router-link to="/">
          <v-img
            :src="require('../../assets/Foremanlogo.jpg')"
            contain
            height="50px"
            class="img-class"
          >
          </v-img>
        </router-link>
      </v-col>
      <v-col cols="12" class="pt-0">
        <h3 class="text-center mt-1 mb-5">Carlos Lopez</h3>
      </v-col>
    </v-row>

    <v-row v-if="dayDate">
      <v-col md="4" class="mb-p d-flex">
        <p>{{ timestamp }}</p>
      </v-col>
      <v-col md="8">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Foreman' })"
            >Main
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="dayDateWithBackBtn">
      <v-col md="4" class="mb-p d-flex">
        <p>{{ timestamp }}</p>
      </v-col>
      <v-col md="8">
        <div class="text-right">
          <v-btn
            color="primary"
            class="mr-2"
            @click="$router.push({ name: 'Foreman' })"
            >Main
          </v-btn>

          <v-btn
            color="primary"
            @click="$router.push({ name: 'TodayProjectMain' })"
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="customer">
      <v-col cols="12" class="mb-p">
        <h2 class="mb-3">{{ project_info.username }}</h2>
        <p><b>Address:</b> {{ project_info.address }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["logo", "customer", "dayDate", "dayDateWithBackBtn"],
  data() {
    return {
      timestamp: new Date().toDateString(),
      project_info: {
        username: "Bill Morrison",
        address: "6221 N. Bales Ave, Gladstone, MO",
      },
      foreman_info: {
        day: "Monday",
        time: "10/26/2020",
      },
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .text-right .v-btn {
    font-size: 12px !important;
    height: auto !important;
    min-width: auto !important;
    padding: 8px !important;
  }
}
</style>
