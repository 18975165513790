<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" day-date="true" />

    <v-row>
      <v-col md="8">
        <h1>CONFIRM TIME CLOCK</h1>
      </v-col>
      <v-col md="4" class="text-right">
        <v-btn color="primary" dark @click="$router.push({ name: 'TimeClock' })"
          >Back
        </v-btn>
      </v-col>
    </v-row>

    <div
      v-for="employeeClock in employeeTimeClock"
      :key="employeeClock.date"
      class="py-5"
    >
      <v-row>
        <v-col md="12">
          <h4>{{ employeeClock.date }}</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12" class="text-right">
          <v-btn
            v-if="canpost === 'true'"
            color="blue-grey"
            dark
            @click="openDialog(employeeClock.date)"
          >
            ADD EMPLOYEE
          </v-btn>
        </v-col>

        <v-col md="12">
          <v-data-table
            :headers="headers"
            :items="employeeClock.items"
            class="elevation-1 cursor-pointer"
          >
            <template v-slot:item.confirm="{}">
              <a>Confirm</a>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="canput === 'true'"
                color="warning"
                small
                class="mr-5"
                @click="editedItems(item, employeeClock.date)"
                >fas fa-edit</v-icon
              >
              <v-icon
                v-if="candelete === 'true'"
                class="cursor-pointer"
                @click="deleteItem(item, employeeClock.date)"
                color="error"
              >
                mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="close">
              <v-img src="../../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>{{ formTitle }}</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-3">
                      <v-col cols="12" sm="12">
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-text-field
                              label="Employee"
                              required
                              v-model="editedItem.employee"
                              :rules="[(v) => !!v || 'This field is required']"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <date-time
                              :time="true"
                              label="Time In"
                              v-model="editedItem.time_in"
                              :timeValue="editedItem.time_in"
                              :reset="reset"
                            >
                            </date-time>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <date-time
                              :time="true"
                              label="Time Out"
                              v-model="editedItem.time_out"
                              :timeValue="editedItem.time_out"
                              :reset="reset"
                            >
                            </date-time>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <date-time
                              :time="true"
                              label="Lunch Time"
                              v-model="editedItem.lunch"
                              :timeValue="editedItem.lunch"
                              :reset="reset"
                            >
                            </date-time>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-text-field
                              label="Project"
                              required
                              v-model="editedItem.project"
                              :rules="[(v) => !!v || 'This field is required']"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="text-right">
                              <v-btn color="primary" dark @click="saveFormData">
                                {{ formButtonTitle }}
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";
import DateTime from "../../components/DateTime/DateTime";

export default {
  name: "ConfirmClock",
  components: { CustomerInfo, DateTime },
  data() {
    return {
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      dialog: false,
      valid: false,
      reset: false,
      confirm: "",
      selectedDate: "",
      selectedTable: "",
      tableIndex: "",
      employeeTimeClock: [],
      editedIndex: -1,
      headers: [
        { text: "Employee", value: "employee" },
        { text: "Time In", value: "time_in" },
        { text: "Time Out", value: "time_out" },
        { text: "Lunch", value: "lunch" },
        { text: "Total Duration", value: "totalDuration" },
        { text: "Project", value: "project" },
        { text: "Confirm", value: "confirm" },
        { text: "Actions", value: "actions" },
      ],
      items: [
        {
          employee: "Carlos",
          time_in: "08:00:00 AM",
          time_out: "04:30:00 PM",
          lunch: "12:30:00 AM",
          formattedDate: "Tuesday 10/24/2020",
          totalDuration: "8.0 Hours",
          project: "Morrison - Gladstone",
        },
        {
          employee: "Chalupa",
          time_in: "08:00:00 AM",
          time_out: "04:30:00 PM",
          lunch: "12:30:00 AM",
          formattedDate: this.formattedDate,
          totalDuration: "8.0 Hours",
          project: "Morrison - Gladstone",
        },
        {
          employee: "Samuel",
          time_in: "08:00:00 AM",
          time_out: "04:30:00 PM",
          lunch: "12:30:00 AM",
          formattedDate: "Tuesday 10/17/2020",
          totalDuration: "8.0 Hours",
          project: "Morrison - Gladstone",
        },
      ],
      editedItem: {
        employee: "",
        time_in: "08:00:00 AM",
        time_out: "04:30:00 PM",
        lunch: "12:30:00 AM",
        project: "",
        totalDuration: "",
      },
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Employee" : "Update Employee";
    },
    formButtonTitle() {
      return this.editedIndex === -1 ? "Add" : "Update";
    },
  },

  created() {
    for (let i = 0; i < 3; i++) {
      const today = new Date();
      const yesterday = today.setDate(today.getDate() - i);
      const date = new Date(yesterday);
      const formattedDate =
        this.days[date.getDay()] +
        " " +
        date.getMonth() +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear();
      const filterItems = this.items.filter(
        (item) => item.formattedDate === formattedDate
      );
      const obj = { date: formattedDate, items: filterItems };
      this.employeeTimeClock.push(obj);
    }
  },
  methods: {
    saveFormData() {
      let startTime = this.$moment(this.editedItem.time_in, "HH:mm:ss a");
      let endTime = this.$moment(this.editedItem.time_out, "HH:mm:ss a");
      this.editedItem.totalDuration = this.$moment
        .utc(
          this.$moment(endTime, "HH:mm:ss a").diff(
            this.$moment(startTime, "HH:mm:ss a")
          )
        )
        .format("HH:mm");
      this.editedItem.totalDuration = this.editedItem.totalDuration + " Hours";
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(
            this.selectedTable.items[this.editedIndex],
            this.editedItem
          );
          Object.assign(
            this.employeeTimeClock[this.tableIndex],
            this.selectedTable
          );
          this.resetItems();
        } else {
          const index = this.employeeTimeClock.findIndex(
            (item) => item.date === this.selectedDate
          );
          this.employeeTimeClock[index].items.push(this.editedItem);
          this.resetItems();
          this.reset = true;
        }
        this.close();
      }
    },
    editedItems(item, date) {
      this.selectedDate = date;
      this.tableIndex = this.employeeTimeClock.findIndex(
        (item) => item.date === this.selectedDate
      );
      this.selectedTable = this.employeeTimeClock[this.tableIndex];
      this.editedIndex = this.selectedTable.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    resetItems() {
      this.editedItem = {
        employee: null,
        time_in: null,
        time_out: null,
        lunch: null,
        project: null,
      };
    },
    openDialog(date) {
      this.selectedDate = date;
      this.dialog = true;
    },
    deleteItem(item, date) {
      this.selectedDate = date;
      this.tableIndex = this.employeeTimeClock.findIndex(
        (item) => item.date === this.selectedDate
      );
      this.selectedTable = this.employeeTimeClock[this.tableIndex];
      const myIndex = this.selectedTable.items.indexOf(item);
      myIndex > -1 ? this.selectedTable.items.splice(myIndex, 1) : false;
    },
    close() {
      this.resetItems();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped></style>
